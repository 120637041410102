import WOW from 'wow.js';

window.addEventListener('load', () => {
  const el = document.getElementById('preloader').style;
  el.opacity = 1;

  (function fade() {
    (el.opacity -= 0.1) < 0 ? (el.display = 'none') : setTimeout(fade, 20);
  })();

  // Initialize wow.js
  new WOW().init();
});
