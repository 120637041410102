import { contents } from '../../assets/contents';

const HomeController = /* @ngInject */ function ($scope, $window) {
  $scope.contents = {};

  this.$onInit = () => {
    $scope.contents = contents;
  };

  $scope.onResumeClick = () => {
    const pdfLink = require('../../assets/shrestha_ajay_cv.pdf');

    $window.open(pdfLink);
  };
};

HomeController.$inject[('$scope', '$window')];

export default {
  template: require('./home.html'),
  controller: HomeController,
};
