import angular from 'angular';
import 'angular-route';
import './utils/onWindowLoad';

import './assets/scss/main.scss';

import routes from './routes';
import HomeComponent from './pages/home/home.component';

angular
  .module('ajayApp', ['ngRoute'])
  .config(routes)
  .component('home', HomeComponent);
