const routes = /* @ngInject */ ($routeProvider, $locationProvider) => {
  // $locationProvider.html5Mode(true);
  // $locationProvider.hashPrefix = '!';

  $routeProvider.when('/', { template: '<home></home>' }).otherwise('/');
  // .otherwise({ redirectTo: '/' });
};

routes.$inject = ['$routeProvider', '$locationProvider'];

export default routes;
